<template>
  <div class="mp-holder">
    <canvas id="mp-canvas"></canvas>
    <img src="./../assets/p1.png" id="mp-img1" class="mp-img" />
    <img src="./../assets/p2.png" id="mp-img2" class="mp-img" />
  </div>
</template>

<script>
// MotionParticlesJS by Aditya Kharote
var MotionParticles = function (canvas, options) {
  this.options = {
    animateScroll: true,
    animateMouse: true,
    images: [],
    updateDelay: 20,
    count: -1,
    allowNegative: false,
    alpha: 1,
    alphaMin: 0,
    randomizeAlpha: true,
    maxSpeed: 1,
    mouseDampFactor: 5,
    scrollDampFactor: 0.8,
    refreshOnResize: false,
  };
  Object.assign(this.options, options);
  this.canvas = canvas;
  this.context = this.canvas.getContext("2d");
  this.particles = [];
  this.width = this.canvas.clientWidth;
  this.height = this.canvas.clientHeight;
  this.canvas.width = this.width;
  this.canvas.height = this.height;
  this.isRunning = false;
  let instance = this;

  window.addEventListener("resize", function () {
    if (instance.options.refreshOnResize) instance.refresh();
    else instance.resize();
  });

  document.addEventListener("mousemove", function (ev) {
    if (!instance.options.animateMouse) return;
    for (var i = 0; i < instance.particles.length; i++) {
      let mod = instance.options.mouseDampFactor;
      let dx =
        Math.sign(ev.clientX - instance.mouseX) * Math.pow(Math.random(), mod);
      let dy =
        Math.sign(ev.clientY - instance.mouseY) * Math.pow(Math.random(), mod);
      if (isNaN(dx)) dx = 1;
      if (isNaN(dy)) dy = 1;
      instance.particles[i].velocityX += dx;
      instance.particles[i].velocityY += dy;
    }
    instance.mouseX = ev.clientX;
    instance.mouseY = ev.clientY;
  });

  window.addEventListener("scroll", function () {
    if (!instance.options.animateScroll) return;
    if (instance.scrollTop == null) instance.scrollTop = 0;
    for (var i = 0; i < instance.particles.length; i++) {
      let mod = instance.options.scrollDampFactor;
      let dy =
        -1 *
        Math.sign(window.scrollY - instance.scrollTop) *
        Math.pow(Math.random(), mod);
      if (isNaN(dy)) dy = 1;
      instance.particles[i].velocityY += dy;
    }
    instance.scrollTop = window.scrollY;
  });

  this.update = function () {
    instance.context.clearRect(0, 0, instance.width, instance.height);

    for (var i = 0; i < instance.particles.length; i++) {
      var p = instance.particles[i];
      var mod = 0.1;

      if (p.velocityX > p.velocityXInit) {
        p.velocityX -= Math.abs(mod);
      } else if (p.velocityX < p.velocityXInit) {
        p.velocityX += Math.abs(mod);
      }

      if (p.velocityY > p.velocityYInit) {
        p.velocityY -= Math.abs(mod);
      } else if (p.velocityY < p.velocityYInit) {
        p.velocityY += Math.abs(mod);
      }

      if (p.x < -32) p.x = instance.width;
      if (p.y < -32) p.y = instance.height;
      if (p.x > instance.width) p.x = -32;
      if (p.y > instance.height) p.y = -32;

      p.x += p.velocityX;
      p.y += p.velocityY;

      instance.context.globalAlpha = p.alpha;
      instance.context.drawImage(p.image, p.x, p.y);
    }
    setTimeout(function () {
      instance.update();
    }, instance.options.updateDelay);
  };

  this.resize = function () {
    instance.width = instance.canvas.clientWidth;
    instance.height = instance.canvas.clientHeight;
    instance.canvas.width = instance.width;
    instance.canvas.height = instance.height;
  };

  this.refresh = function () {
    instance.resize();
    if (instance.options.images == null || instance.options.images.length == 0)
      return;
    this.particles = [];
    let count =
      instance.options.count == -1 || instance.options.count == null
        ? instance.width / 20
        : instance.options.count;
    for (let i = 0; i < parseInt(count); i++) {
      let particle = {};
      particle.velocityX = Math.random() * instance.options.maxSpeed;
      particle.velocityY = Math.random() * instance.options.maxSpeed;
      if (instance.options.allowNegative) {
        if (Math.random() < 0.5) particle.velocityX *= -1;
        if (Math.random() < 0.5) particle.velocityY *= -1;
      }
      particle.velocityXInit = particle.velocityX;
      particle.velocityYInit = particle.velocityY;
      particle.alpha = instance.options.alpha - instance.options.alphaMin;
      if (instance.options.randomizeAlpha) {
        particle.alpha *= Math.random();
      }
      particle.alpha += instance.options.alphaMin;
      particle.x = Math.random() * instance.width;
      particle.y = Math.random() * instance.height;
      let k = Math.floor(Math.random() * instance.options.images.length);
      particle.image = instance.options.images[k];

      instance.particles.push(particle);
    }

    if (!instance.isRunning) {
      instance.update();
      instance.isRunning = true;
    }
  };

  this.setOptions = function (opts) {
    Object.assign(instance.options, opts);
    instance.resize();
  };

  this.refresh();
};



export default {
  name: "MotionCanvas",
  mounted() {
      new MotionParticles(document.getElementById("mp-canvas"),{
            images:[
                document.getElementById("mp-img1"),
                document.getElementById("mp-img2")
            ],
            alpha: 0.1,
            mouseDampFactor: 3,
            refreshOnResize: true
        })
  }
};
</script>

<style>
    #mp-canvas{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .mp-img{
        display: none;
    }
</style>