<template>
    <a :style="{'background': bgcolor || 'var(--color-primary)','color': textcolor || 'var(--color-accent)'}" :target="target||''" 
        :class="!bordered ? 'bigbtn' : 'bigbtn bigbtn-border'" :href="href">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        name: "BigButton",
        props: ["href","textcolor","bgcolor","target","bordered"]
    }
</script>

<style>
    .bigbtn{
        background: var(--color-primary);
        background: var(--color-primary);
        font-weight: 700;
        font-size: 12px;
        text-decoration: none;
        padding: 16px 64px;
        display: inline-block;
        width: fit-content;
        transition: all 0.25s;
        clip-path: polygon(0% 0%, calc(100% - 32px) 0%, 100% 100%, 32px 100%);
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 8px;
        text-align: center;
    }

    .bigbtn:hover{
        clip-path: polygon(0px 0%, calc(100% - 40px) 0%, calc(100% - 0px) 100%, 40px 100%);
    }

    .bigbtn:active{
        clip-path: polygon(0px 0%, calc(100% - 48px) 0%, calc(100% - 0px) 100%, 48px 100%);
    }

    .bigbtn-border{
        background: transparent !important;
        border: solid 2px var(--color-accent);
    }
</style>