<template>
    <div class="event-card-item">
        <img class="event-card-image" :src="event.image" />
        <div class="event-card-content">
            <div class="custom-shape-divider-bottom-1632736404">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                    preserveAspectRatio="none">
                    <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z" class="shape-fill">
                    </path>
                </svg>
            </div>
            <div class="event-card-content-title">
                <span>{{event.title}}</span>
                <span
                    style="font-size: 12px; font-weight: 500; color: var(--color-accent); letter-spacing: 0.12em; margin-top: 4px">{{event.date}}</span>
            </div>

            <div class="event-card-content-inner">
                <span class="m-ellipsis" style="font-size: 14px; color: rgba(255,255,255,0.75); overflow: hidden; ">
                    {{event.summary}}
                </span>
                <a class="event-btn" v-on:click="learnMore">Learn More</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EventCard",
        props: ["event"],
        methods: {
            learnMore(){
                if(this.event.learnMore) window.location = this.event.learnMore;
                else this.$root.$emit('show-event-popup',this.event)
            }
        },
    }
</script>

<style>
    .event-card-item {
        display: block;
        width: 320px;
        height: 360px;
        background: var(--color-primary-dark);
        margin: 8px 4px;
        overflow: hidden;
        flex-grow: 1;
        box-shadow: 0px 2.8px 2.2px rgb(165 240 211 / 1%), 0px 6.7px 5.3px rgb(165 240 211 / 1%), 0px 12.5px 10px rgb(165 240 211 / 1%), 0px 22.3px 17.9px rgb(165 240 211 / 1%), 0px 41.8px 33.4px rgb(165 240 211 / 1%), 0px 100px 80px rgb(165 240 211 / 2%);
    }

    .event-card-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .event-card-content {
        width: 100%;
        height: 292px;
        margin-top: -84px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        transition: margin 0.25s ease;
    }

    .event-card-content-title {
        min-height: 72px;
        padding: 16px 24px;
        color: #FFF;
        font-weight: 600;
        letter-spacing: 0.065em;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: var(--color-primary-dark);
        text-transform: uppercase;
    }

    .event-card-content-title span {
        margin-top: -22px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
    }

    .event-card-content-inner {
        flex-grow: 1;
        background: var(--color-primary-dark);
        padding: 0px 24px 4px 24px;
        display: flex;
        flex-direction: column;
    }

    .event-card-item:hover .event-card-content {
        margin-top: calc(-72px - 220px);
    }

    .event-btn {
        width: 100%;
        padding: 12px 24px;
        background: var(--color-accent);
        text-transform: none;
        color: var(--color-primary-dark);
        font-weight: 700;
        font-size: 14px;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: 16px;
        justify-content: center;
        display: flex;
        transition: all 0.25s;
        cursor: pointer;
        text-decoration: none;
    }

    .event-btn::before {
        content: ' ';
        width: 0px;
        height: 100%;
        background: var(--color-primary-dark);
        margin-right: 0px;
        transition: all 0.25s;
    }

    .event-btn:hover::before {
        margin-right: 16px;
        width: 2px;
    }

    .event-btn:active::before {
        margin-right: 4px;
        width: 2px;
    }

    .custom-shape-divider-bottom-1632736404 {
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        margin-bottom: -2px;
    }

    .custom-shape-divider-bottom-1632736404 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 24px;
    }

    .custom-shape-divider-bottom-1632736404 .shape-fill {
        fill: var(--color-primary-dark);
    }

    @media screen and (min-width: 640px) {
        .event-card-item{
            max-width: calc(50% - 8px);
        }
    }

    @media screen and (min-width: 1180px) {
        .event-card-item{
            max-width: calc(33.33333% - 8px);
        }
    }
</style>