<template>
    <transition name="fade">
        <div v-if="isVisible" class="event-popup-outer">
            <div class="event-popup">
                <div class="event-popup-close">
                    <img src="./../assets/mnav_close.png" v-on:click="hideEventPopup">
                </div>
                <div class="event-popup-title">
                    <span style="font-size: 12px; font-weight: 600; letter-spacing: 0.15em;">{{eventData.date}}</span>
                    <span style="margin-top: 8px;">{{eventData.title}}</span>
                    <span style="font-size: 16px; font-weight: 400; text-transform: uppercase; letter-spacing: 0.1em; margin-top: 8px; opacity: 0.8;">
                        {{eventData.subtext}}
                    </span>
                    <a style="margin-top: 16px;" target="_blank" v-if="eventData.actionURL" class="event-btn" :href="eventData.actionURL">{{eventData.actionText || "Register Now"}}</a>
                    <vue-markdown style="margin-top: 8px" class="event-popup-desc">
                        {{eventData.description}}
                    </vue-markdown>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import VueMarkdown from '@adapttive/vue-markdown'
    export default {
        name: "EventPopup",
        components: {
            VueMarkdown
        },
        data(){
            return {
                eventData: {},
                isVisible: false
            }
        },
        mounted: function(){
            this.$root.$on('show-event-popup',data=>{
                this.eventData = data;
                this.isVisible = true;
            })
        },
        methods: {
            hideEventPopup(){
                this.isVisible = false;
            }
        }
    }
</script>

<style>
    .event-popup-outer {
        display: flex;
        width: 100%;
        height: 100vh;
        position: fixed;
        background: rgba(0,0,0,0.75);
        z-index: 32;
        top: 0;
        justify-content: center;
        align-items: center;
    }

    .event-popup{
        width: 90%;
        max-width: 640px;
        padding: 24px 32px;
        background: var(--color-popup);
        border-radius: 8px;
        flex-direction: column;
        max-width: 80vh;
        overflow-y: auto;
    }
    
    .event-popup-title{
        font-size: 24px;
        font-weight: 600;
        color: var(--color-accent);
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
    }
    
    .event-popup-desc{
        font-size: 14px; 
        font-weight: 500; 
        letter-spacing: 0.1em; 
        text-transform: none;
        color: rgba(255,255,255,0.8);
        line-height: 20px;
    }

    .event-popup-desc a{
        color: var(--color-accent);
    }

    .event-popup-close{
        margin-left: auto;
        display: flex;
        position: absolute;
        width: calc(100% - 32px);
        z-index: 33;
    }

    .event-popup-close img:hover{
        background: rgba(0,0,0,0.25);
        
    }

    .event-popup-close img{
        width: 32px;
        height: 32px;
        margin-left: auto;
        margin-right: 32px;
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .25s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>