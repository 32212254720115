import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: 'CSI SPIT' }
  },
  {
    path: "/team",
    name: "Team",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Team.vue"),
    meta: { title: 'Team | CSI SPIT' }
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Events.vue"),
    meta: { title: 'Events | CSI SPIT' }
  },
  {
    path: "/hackathon",
    name: "Hackathon",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Hackathon.vue"),
    meta: { title: 'SPIT Hackathon' }
  },
  {
    path: "/community",
    name: "Community",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Community.vue"),
    meta: { title: 'Community | CSI SPIT' }
  },
  {
    path: "/account/login",
    name: "Sign In",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dashboard/SignIn.vue"),
    meta: { title: 'Sign In | CSI SPIT' }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
      document.title = to.meta.title || "CSI SPIT";
  });
});

export default router;
