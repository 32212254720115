<template>
    <div class="footer">
        <div class="footer-inner">
            <div class="footer-section">
                <span class="footer-title">Let's Connect!</span>
                <a href="https://github.com/CSI-SPIT" target="_blank"><i class="fab fa-github"></i> GitHub</a>
                <a href="https://www.linkedin.com/company/s-p-i-t-csi/" target="_blank"><i class="fab fa-linkedin"></i> LinkedIn</a>
            </div>
            <div class="footer-section">
                <span class="footer-title">Social Media</span>
                <a href="https://instagram.com/csispit/" target="_blank"><i class="fab fa-instagram"></i> Instagram</a>
                <a href="https://www.facebook.com/CSI.SPIT/" target="_blank"><i class="fab fa-facebook"></i> Facebook</a>
            </div>
        </div>
        <div class="footer-end">
            Developed By <a href="https://www.linkedin.com/in/aditya-kharote-b280b6135/" target="_blank">Aditya Kharote</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
    }
</script>

<style>
    .footer{
        width: 100%;
        background: var(--color-primary-dark);
        min-height: 128px;
        color: var(--color-accent);
        border-top: dotted 2px var(--color-accent);
        padding: 0px 128px;
        display: flex;
        flex-direction: column;
    }

    .footer-inner{
        display: flex;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .footer-end{
        width: 100%;
        padding: 24px 0px;
        text-align: center;
        border-top: solid 1px #a5f0d32f;
    }

    .footer-section{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-section i{
        margin-right: 16px;
        color: var(--color-accent);
    }

    .footer-title{
        font-weight: 600;
        font-size: 14px;
    }

    .footer a{
        color: #FFF;
        text-decoration: none;
        margin-top: 16px;
        transition: color 0.25s;
        width: fit-content;
    }

    .footer a:hover{
        color: var(--color-accent);
    }

    @media screen and (max-width: 1280px) {
        .footer{
            padding: 0px 96px;
        }
    }

    @media screen and (max-width: 840px) {
        .footer{
            padding: 0px 32px;
        }

        .header-holder{
            align-items: center;
            text-align: center;
        }
    }
</style>