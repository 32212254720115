<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init(
  {
    duration: 500
  }
);

export default {
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'CSI SPIT';
        }
    },
  }
}
</script>

<style>
html,
body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0px;
  background: var(--color-primary);
  color: #fff;
  z-index: 1;
  scroll-behavior: smooth;
}

html{
  overflow-y: auto;
}

* {
  box-sizing: border-box;
  z-index: 1;
  position: relative;
}

#app{
	z-index: 1;
  overflow-x: hidden;
}

:root {
  --color-primary: #070c1d;
  --color-primary-light: rgb(16, 34, 44);
  --color-primary-dark: #040816;
  --color-popup: #161b2d;
  --color-secondary: #a5f0d3;
  --color-accent: #a5f0d3;
}

.container{
	width: 100%;
	padding: 0px 144px;
  transition: padding 0.2s;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.m-ellipsis{
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1280px) {
  .container{
    padding: 0px 96px;
  }
}

@media screen and (max-width: 840px) {
  .container{
    padding: 0px 32px;
  }

  .header-holder{
    align-items: center;
    text-align: center;
  }
}
</style>
