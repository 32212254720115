<template>
  <div class="navbar-outer">
    <div class="navbar" :style="{background: navTransparent ? 'transparent' : 'var(--color-primary)'}">
      <a href="/"><img class="navlogo" src="./../assets/csi_logo.png" /></a>
      <div class="navbtn-holder">
        <router-link to="/" class="navbtn" v-bind:navactive="this.navselected=='home'">Home</router-link>
        <router-link to="/events" class="navbtn" v-bind:navactive="this.navselected=='events'">Events</router-link>
        <router-link to="/team" class="navbtn" v-bind:navactive="this.navselected=='team'">Our Team</router-link>
        <router-link to="/hackathon" class="navbtn" v-bind:navactive="this.navselected=='hackathon'">Hackathon</router-link>
        <!-- <router-link to="/community" class="navbtn" v-bind:navactive="this.navselected=='community'">Community</router-link> -->
        <div class="mnav-toggle" v-on:click="triggerMnav">
          <img v-if="!mnavOpen" class="mnav-open" src="./../assets/mnav_open.png"/>
          <img v-if="mnavOpen" class="mnav-open" src="./../assets/mnav_close.png"/>
        </div>
        <router-link v-if="config.userSysEnabled" to="/account/login" class="navbtn">Sign In</router-link>
        <a v-if="config.userSysEnabled" class="nav-account">
          <div class="nav-account-inner" v-on:click="triggerAccDropdown">
            <img src="https://via.placeholder.com/96" />
            <i class="fas fa-chevron-down"></i>
          </div>
        </a>
      </div>
      <div v-if="accDropActive" class="acc-drop">
        <a class="acc-drop-item">Dashboard</a>
        <a class="acc-drop-item">Your Events</a>
        <a class="acc-drop-item">Certificates</a>
        <a class="acc-drop-item">Settings</a>
        <a class="acc-drop-item">Logout</a>
      </div>
      <div v-if="mnavOpen" class="mnav">
        <router-link to="/" class="mnavbtn" v-bind:navactive="this.navselected=='home'">Home</router-link>
        <router-link to="/events" class="mnavbtn" v-bind:navactive="this.navselected=='events'">Events</router-link>
        <router-link to="/team" class="mnavbtn" v-bind:navactive="this.navselected=='team'">Our Team</router-link>
        <router-link to="/hackathon" class="mnavbtn" v-bind:navactive="this.navselected=='hackathon'">Hackathon</router-link>
        <!-- <router-link to="/community" class="mnavbtn" v-bind:navactive="this.navselected=='community'">Community</router-link> -->
      </div>
    </div>
    <div style="height: var(--nav-height);"></div>
  </div>
</template>

<script>
import config from '@/config.js'
export default {
  name: "Navbar",
  methods: {
    triggerAccDropdown(){
      this.accDropActive = !this.accDropActive;
    },
    triggerMnav(){
      this.mnavOpen = !this.mnavOpen;
    }
  },
  data() {
    return {
      accDropActive: false,
      mnavOpen: false,
      config: config,
      navTransparent: false
    }
  },
  mounted(){
    window.addEventListener('scroll',()=>{
      this.navTransparent = window.scrollY<100;
    })
  },
  props: ["navselected"]
};
</script>

<style scoped>
.navbar-outer{
  --nav-height: 96px;
  width: 100%;
  height: var(--nav-height);
  z-index: 2;
}

.navbar {
  width: 100%;
  height: var(--nav-height);
  padding: 0px 144px;
  display: flex;
  flex-direction: row;
  transition: all 0.25s;
  top: 0;
  left: 0;
  z-index: 2;
  position: fixed;
}

.navlogo {
  height: var(--nav-height);
  padding: 24px;
}

.navbtn-holder {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}
.navbtn {
  display: flex;
  flex-direction: column;
  height: var(--nav-height);
  padding: 16px 24px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Manrope;
  font-weight: 700;
  letter-spacing: 0.15em;
  padding-top: 32px;
  cursor: pointer;
  transition: all 0.25s ease;
  color: white;
  text-decoration: none;
}

.navbtn:hover {
  padding-bottom: 24px;
}

.navbtn[navactive=true],
.navbtn:hover {
  color: var(--color-accent);
}

.navbtn::after {
  display: block;
  content: " ";
  width: 0px;
  height: 1px;
  background: var(--color-accent);
  margin-top: 16px;
  border-radius: 48px;
  transition: width 0.25s ease;
}

.navbtn:hover::after {
  width: 48px;
}

.navbtn:active::after {
  width: 32px;
}

.navbtn[navactive=true]::after {
  width: 24px;
}

.nav-account {
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-account-inner {
  background: rgba(255, 255, 255, 0);
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background 0.15s;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.nav-account-inner:hover {
  background: rgba(255, 255, 255, 0.1);
}

.nav-account-inner:active {
  background: rgba(255, 255, 255, 0.05);
}

.nav-account-inner img {
  width: 48px;
  height: 48px;
  padding: 6px;
  border-radius: 50%;
}
.nav-account-inner i {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 16px;
}

.mnav-toggle{
  height: var(--nav-height);
  display: none;
  cursor: pointer;
  filter: var(--nav-filter);
  -webkit-filter: var(--nav-filter);
  -webkit-tap-highlight-color: transparent;
}

.mnav-toggle img{
  height: 100%;
  padding: 32px;
}

.mnav{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: var(--nav-height);
  min-height: calc(100vh - var(--nav-height));
  background: var(--color-accent);
  animation: dd-show 0.25s;
  transform-origin: top;
  border-radius: 32px 32px 0px 0px;
  display: none;
  flex-direction: column;
}

.mnavbtn{
  width: calc(100% - 16px);
  padding: 24px 32px;
  border-bottom: solid 1px #070c1d1f;
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 32px;
  margin: 8px;
  transition: all 0.25s;
  -webkit-tap-highlight-color: transparent;
}

.mnavbtn[navactive=true],
.mnavbtn:hover {
  color: var(--color-accent);
  background: var(--color-primary);
  -webkit-tap-highlight-color: transparent;
}

.mnavbtn:hover{
  padding-left: 48px;
}

.acc-drop{
  display: flex;
  flex-direction: column;
  width: 240px;
  background: var(--color-secondary);
  position: absolute;
  top: 96px;
  right: 160px;
  z-index: 10;
  transform-origin: top;
  border-radius: 4px;
  animation: dd-show 0.25s
}

.acc-drop-item{
  display: flex;
  width: 100%;
  padding: 12px 24px;
  color: var(--color-primary);
  font-size: 12px;
  font-weight: 600;
  transition: all 0.25s;
  border: solid 2px transparent;
  border-radius: 4px !important;
  cursor: pointer;
}

.acc-drop-item:hover{
  background: var(--color-primary);
  color: var(--color-secondary);
  border: solid 2px var(--color-secondary);
  padding-left: 32px;
}
.acc-drop::before{
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: var(--color-accent);
  border-width: 10px;
  margin-top: -20px;
  right: 56px;
}

@keyframes dd-show {
  from{
    transform: scaleY(0);
    opacity: 0;
  }
  to{
    transform: scaleY(1);
    opacity: 1;
  }
}

@media screen and (max-width: 1280px) {
  .navbar{
    padding: 0px 64px;
  }

  .acc-drop{
    right: 80px;
  }
}

@media screen and (max-width: 1080px) {
  .navbar{
    padding: 0px;
  }

  .acc-drop{
    right: 16px;
  }
}

@media screen and (max-width: 960px) {
  .navbtn{
    display: none;
  }

  .mnav-toggle{
    display: block;
  }

  .mnav{
    display: flex;
  }
}
</style>
