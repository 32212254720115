<template>
  <div>
    <navbar navselected="home" />
    <motion-canvas />
    <event-popup />
    <div class="container header-holder"  data-aos="fade-right">
      <span style="color: var(--color-accent); font-weight: 700; font-size: 14px; letter-spacing: 0.32em;">WELCOME TO</span>
      <span style="font-weight: 700; font-size: 64px; letter-spacing: 0.225em; margin-top: 8px;">CSI<span style="color: var(--color-accent)">-</span>SPIT</span>
      <span style="font-weight: 600; font-size: 16px; letter-spacing: 0.15em; margin-top: 16px;">
        Think<span style="color: var(--color-accent)">.</span>
        Improvise<span style="color: var(--color-accent)">.</span>
        Create<span style="color: var(--color-accent)">.</span>
      </span>
      <p>
        CSI-SPIT is an altruistic society in college initiating workshops and events to explore the cornucopia of information.
      </p>
    </div>
    <div class="custom-shape-divider-bottom-1632677197">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
      </svg>
    </div>
    <div class="about-section container">
      <span style="font-size: 36px; font-weight: 800; letter-spacing: 0.05em; text-align: right; margin-top: -64px;">ABOUT US</span>
      <div style="font-weight: 500; margin-top: 32px; font-size: 16px; line-height: 24px;"  data-aos="fade-right">
        We are the C.S.I. Student Branch of Sardar Patel Institute of Technology. Formed in 2009, CSI-SPIT is an altruistic society in college initiating workshops, events and seminars to explore the cornucopia of information other than the regular curriculum offered by the university. As part of our endeavour to bring together and assimilate various aspects of technical and non-technical education, number of seminars and workshops are conducted by professionals imparting knowledge to the students of the college. We also encourage member students to organize events by themselves so that it imbibes in them the skills of management, self-confidence and helps to exchange views and information, learn and share ideas. 
        <div class="mentor">
          <img :src="'./images/team/devadkarsir.jpeg'"/>
          <div style="display: flex; flex-direction: column; margin-left: 24px">
            <span style="font-size: 16px; font-weight: 600">
              Prof. Kailas K. Devadkar
            </span>
            <span style="font-size: 14px; font-weight: 400">
              Faculty Co-ordinator
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-shape-divider-bottom-1632677197" style="transform: rotate(0deg) scaleY(0.25); transform-origin: top;">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
      </svg>
    </div>
    <div class="upcoming-events container">
      <span class="styled-title" style="font-size: 36px; font-weight: 800; letter-spacing: 0.05em;"><i class="fa fa-calendar" style="color: var(--color-accent); margin-right: 16px;"></i> RECENT EVENTS</span>
      <div class="events-holder"  data-aos="fade-up">
        <event-card v-for="(event, i) in upcomingEvents" v-bind:key="i" :event="event"></event-card>
      </div>
      <big-button style="width: 256px" textcolor="var(--color-primary)" bgcolor="var(--color-accent)" href="./events">VIEW ALL</big-button>
    </div>
    <div class="container" style="display: flex; flex-direction: column; margin-top: 48px">
      <span class="styled-title" style="font-size: 36px; font-weight: 800; letter-spacing: 0.05em;"><i class="fa fa-map-marker-alt" style="color: var(--color-accent); margin-right: 16px;"></i> FIND US</span>
      <div class="find-section">
        <div style="display: flex; flex-direction: column; flex-grow: 1; width: 100%; padding-right: 64px; font-size: 18px;">
          <span style="font-size: 24px; font-weight: 600">Sardar Patel Institute of Technology</span>
          <span style="margin-top: 36px">Bhavan’s Campus, Munshi Nagar, <br>Andheri (West), Mumbai 400 058</span>
          <span style="margin-top: 32px;">
            <span style="color: var(--color-accent); font-weight: 600"><i class="fa fa-envelope" style="margin-right: 8px;"></i> Email: </span>
            csi@spit.ac.in
          </span>

          <span style="margin-top: 32px;">
            <span style="color: var(--color-accent); font-weight: 600"><i class="fa fa-phone-alt" style="margin-right: 8px;"></i> Sanika Tiwarekar: </span>
            +91 97690 95698
          </span>

          <span style="margin-top: 32px;">
            <span style="color: var(--color-accent); font-weight: 600"><i class="fa fa-phone-alt" style="margin-right: 8px;"></i> Shubh Gupta: </span>
            +91 70214 11053
          </span>

          <span style="margin-top: 32px;">
            <span style="color: var(--color-accent); font-weight: 600"><i class="fa fa-phone-alt" style="margin-right: 8px;"></i> Deepanshu Aggarwal: </span>
            +91 70060 61747
          </span>
        </div>
        
        <iframe style="border:0 flex-grow: 1; border-radius: 16px"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.645240101157!2d72.83392095091203!3d19.123213055391627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d90e125239%3A0x80b8d1fb8ac5aeb9!2sBharatiya+Vidya+Bhavan&#39;s+Sardar+Patel+Institute+Of+Technology!5e0!3m2!1sen!2sin!4v1539866645329"
								width="100%" height="450" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <m-footer></m-footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MotionCanvas from "@/components/MotionCanvas.vue";
import EventCard from "@/components/EventCard.vue";
import EventPopup from "@/components/EventPopup.vue";
import BigButton from "@/components/BigButton.vue";
import Footer from "@/components/Footer.vue";
import eventData from "@/assets/content/events.json";

export default {
  name: "Home",
  components: {
    Navbar,
    MotionCanvas,
    EventCard,
    EventPopup,
    BigButton,
    MFooter: Footer
  },
  data(){
    return {
      events: eventData.events
    } 
  },
  computed: {
    upcomingEvents:  function(){
      return this.events.slice(0,3)
    }
  }
};
</script>

<style>
  .find-section{
    margin: 36px 0px; 
    display: flex; 
    flex-direction: row;
    align-items: center;
  }

    @media screen and (max-width: 940px) {
      .find-section{
        flex-direction: column;
      }

      .find-section iframe{
        margin-top: 32px;
      }
    }
</style>

<style scoped>
  .header-holder{
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    padding-top: 64px;
    min-height: calc(100vh - 320px);
    justify-content: center;
  }
  .header-holder p{
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    letter-spacing: 0.15em;
    line-height: 26px;
    max-width: 512px;
    margin-top: 48px;
  }

  .custom-shape-divider-bottom-1632677197 {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1632677197 svg {
      position: relative;
      display: block;
      width: calc(100% + 1.3px);
      height: 240px;
      transform: rotateY(180deg);
  }

  .custom-shape-divider-bottom-1632677197 .shape-fill {
      fill: #a5f0d3;
  }

  .about-section{
    background: var(--color-accent);
    color: var(--color-primary-dark);
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
  }

  .mentor{
    display: flex;
    align-items: center;
    margin: 24px 0px;
  }
  .mentor img{
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .upcoming-events{
    padding-bottom: 32px;
    margin-top: -96px;
  }

  .events-holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 480px) {
    .header-holder{
      justify-content: start;
    }
  }
  
</style>
